import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
// import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import PreChorus from "../../components/PreChorus.js";
import Verse from "../../components/Verse.js";

export default function DaysAreGoingFast() {
  return (
    <Layout>
      <Helmet title="Days Are Going Fast | Michael Weslander & Rob Andrews" />

      <h1>Days Are Going Fast</h1>
      <h2>Michael Weslander & Rob Andrews</h2>
      <h4>Key: Am</h4>
      <h4>Tempo: 110</h4>
      <Link to="/">List</Link>

      <Columns columns="3">
        <Verse>
          <p>Rifle through the drawers for what I thought I’d found</p>
          <p>Organize the cables, ten times around</p>
          <p>(Should we)</p>
          <p>Order in some food</p>
          <p>Pretend it’s like a date</p>
          <p>Nah, let’s do that all tomorrow</p>
          <p>It’s getting late</p>
        </Verse>
        <PreChorus>
          <p>Almost all of my plans fall through</p>
          <p>When I’m stuck in this loop</p>
          <p>(Loop, loop, loop, loop, loop, loop) // break</p>
        </PreChorus>
        <Chorus>
          <p>Days are going fast</p>
          <p>But the hours feel longer</p>
        </Chorus>
        <Verse>
          <p>Pensively staring at the window sill</p>
          <p>Another late night project save can’t you tell</p>
          <p>sleeping through the weekends</p>
          <p>Working through the nights</p>
          <p>The worst of all my tendencies</p>
          <p>Have come to light</p>
        </Verse>
        <PreChorus>
          <p>Another plan falls through</p>
          <p>Stuck in this loop</p>
        </PreChorus>
        <Chorus>
          <p>Days are going fast</p>
          <p>But the hours feel longer</p>
        </Chorus>
        <Bridge>
          CHORDS
        </Bridge>
        <Chorus>
          <p>Days are going fast</p>
          <p>But the hours feel longer</p>
        </Chorus>
        <Chorus>
          <p>Days are going fast</p>
          <p>But the hours feel longer</p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
